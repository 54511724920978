<!-- <a mat-list-item [ngStyle]="{'padding-left': (depth * 12) + 'px'}" (click)="onItemSelected(item)"
   class="menu-list-item" style="color: #d9d9d9;"> -->

<!-- <a mat-list-item [ngStyle]="{'padding-left': (depth * 12) + 'px'}" 
   class="menu-list-item" style="color: #d9d9d9; width: 100%;">
   
  <span (click)="onItemSelected(item)" class="nombreItem">{{item.strNombre}}</span>
  
  <span fxFlex *ngIf="item.miListGrupoSub1 && item.miListGrupoSub1.length" style="color: #d9d9d9;" (click)="expanded = !expanded" class="itemExpand">
    <span fxFlex style="background-color: brown;"></span>
    <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">
      expand_more
    </mat-icon>
  </span>
</a> -->

<a [ngStyle]="{'padding-left': (depth * 12) + 'px'}" 
   class="menu-list-item" style="color: #d9d9d9;">
   
  <div  class="nombreItem">
    <span (click)="onItemSelected(item)">{{item.strNombre}}</span>
  </div>
  
  <div  *ngIf="item.miListGrupoSub1 && item.miListGrupoSub1.length" style="color: #d9d9d9;" (click)="expanded = !expanded" class="itemExpand">
    <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">
      expand_more
    </mat-icon>
  </div>
</a>

<div *ngIf="expanded">
  <app-menu-list-item *ngFor="let child of item.miListGrupoSub1" [item]="child" [depth]="depth+1" (click)="clickChield(child)">
  </app-menu-list-item>
</div>

<nav class="miSpinner" [style.display]="showSpinner ? 'block' : 'none'" >
	<mat-spinner ></mat-spinner>
</nav>
