<!-- <mat-toolbar >
    <mat-toolbar-row>

      <nav class="logoApp">
        <div class="img"> <img src="data:image/gif;base64,{{imgLogo}}" width="35"></div>
        <div class="texto">{{textoApp}}</div>
      </nav>

      <span *ngIf="abierto" (click)="closeNav()" class="menu">
        <span><mat-icon>menu</mat-icon></span>        
      </span>                      
      <span *ngIf="cerrado" class="menu" (click)="openNav()"><mat-icon>menu</mat-icon></span>

      <div>
        <div class="instalacion">
          <img src="../../../assets/account.png" width="28" class="imagenAccount" (click)="hide = !hide">     
           
          <ul class="accountMenu" [style.display]="hide ? 'none' : 'block'">
            <span class="with-arrow">
              <span class="bg-primary"></span>
            </span> 
            <li class="user"><a> Hola, {{loginUserData.strUserName}} </a></li>
            <li (click)="logout()"><a> <img src="../../../assets/logout_black.png" width="16"> Salir</a></li>
          </ul>   

        </div>
    </div>
  
    </mat-toolbar-row>
</mat-toolbar>
  
<div class="miMenu22" id="miSidenav">
    <a routerLink="../clientes" routerLinkActive="active-link" *ngIf="isAdmin"> <img src="../../../assets/contacts.png" width="16"> Clientes</a>
    <a routerLink="../ordenes-cobro" routerLinkActive="active-link"> <img src="../../../assets/payment2.png" width="16"> Orden cobros</a>
    <a routerLink="../configuracion" routerLinkActive="active-link" *ngIf="isAdmin"> <img src="../../../assets/settings.png" width="16"> Configuración</a>
</div>  


<nav class="miSpinner" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
</nav>

<br>
<section class="contenido">
  <router-outlet></router-outlet>
</section> -->


<!-- /******************************* OTRO EJEMPLO  ***************************************/

<mat-toolbar color="primary" class="example-toolbar">
	<button mat-icon-button (click)="isExpanded = !isExpanded"><mat-icon>menu</mat-icon></button>
	<h1 class="example-app-name">App Nueva</h1>
</mat-toolbar>


<mat-sidenav-container class="example-container" autosize>
	<mat-sidenav #sidenav class="example-sidenav" mode="side" opened="true" (mouseenter)="mouseenter()" (mouseleave)="mouseleave()">
		<mat-nav-list>
			<mat-list-item (click)="showSubmenu = !showSubmenu" class="parent">
        <img src="../../../assets/contacts.png" width="16" class="iconoMenu">
				<span class="full-width" *ngIf="isExpanded || isShowing">Parent Menu</span>        
        <img src="../../../assets/contacts.png" width="16" class="menu-button" [ngClass]="{'rotated' : showSubmenu}" *ngIf="isExpanded || isShowing">
			</mat-list-item>
			<div class="submenu" [ngClass]="{'expanded' : showSubmenu}" *ngIf="isShowing || isExpanded">
				<a mat-list-item href="...">Submenu Item 1</a>
        <a mat-list-item href="...">Submenu Item 2</a>
        <a mat-list-item href="...">Submenu Item 3</a>
				<mat-list-item (click)="showSubSubMenu = !showSubSubMenu" class="parent">
					<span class="full-width" *ngIf="isExpanded || isShowing">Nested Menu</span>
					<mat-icon class="menu-button" [ngClass]="{'rotated' : showSubSubMenu}" *ngIf="isExpanded || isShowing">expand_more</mat-icon>
				</mat-list-item>
				<div class="submenu" [ngClass]="{'expanded' : showSubSubMenu}" *ngIf="isShowing || isExpanded">
					<mat-list-item>SubSubmenu Item 1</mat-list-item>
					<mat-list-item>SubSubmenu Item 2</mat-list-item>
				</div>
			</div>
		</mat-nav-list>
	</mat-sidenav>

	<div class="example-sidenav-content">
		Main content that resizes properly
	</div>

</mat-sidenav-container> -->



<!-- <mat-toolbar color="primary" class="example-toolbar">
  <button mat-icon-button (click)="drawer.toggle()"><mat-icon>menu</mat-icon></button> 
  <div class="contentButonToolbar">
    <button mat-icon-button (click)="isExpanded = !isExpanded"><mat-icon>menu</mat-icon></button>
  </div>  
	<h1 class="example-app-name">App Nueva</h1>
</mat-toolbar> -->






<mat-sidenav-container class="example-container" autosize>

	<mat-sidenav #sidenav class="example-sidenav" mode="side" opened="true" (mouseenter)="mouseenter()" (mouseleave)="mouseleave()">
		<mat-nav-list>
			<mat-list-item (click)="showSubmenu = !showSubmenu" class="parent">
        <img src="../../../assets/contacts.png" width="16" class="iconoMenu">
				<span class="full-width" *ngIf="isExpanded || isShowing">Parent Menu</span>        
        <img src="../../../assets/contacts.png" width="16" class="menu-button" [ngClass]="{'rotated' : showSubmenu}" *ngIf="isExpanded || isShowing">
			</mat-list-item>
			<div class="submenu" [ngClass]="{'expanded' : showSubmenu}" *ngIf="isShowing || isExpanded">
				<a mat-list-item href="...">Submenu Item 1</a>
        <a mat-list-item href="...">Submenu Item 2</a>
        <a mat-list-item href="...">Submenu Item 3</a>
				<mat-list-item (click)="showSubSubMenu = !showSubSubMenu" class="parent">
					<span class="full-width" *ngIf="isExpanded || isShowing">Nested Menu</span>
					<mat-icon class="menu-button" [ngClass]="{'rotated' : showSubSubMenu}" *ngIf="isExpanded || isShowing">expand_more</mat-icon>
				</mat-list-item>
				<div class="submenu" [ngClass]="{'expanded' : showSubSubMenu}" *ngIf="isShowing || isExpanded">
					<mat-list-item>SubSubmenu Item 1</mat-list-item>
					<mat-list-item>SubSubmenu Item 2</mat-list-item>
				</div>
			</div>
		</mat-nav-list>
	</mat-sidenav>
  
    <mat-drawer #sidenav class="example-sidenav contentButon2" mode="side" (mouseenter)="mouseenter()" >
      <button mat-icon-button (click)="sidenav.toggle()" class="contentButon2"><mat-icon>clear</mat-icon></button>
      <mat-nav-list>
        <mat-list-item (click)="showSubmenu = !showSubmenu" class="parent">
          <img src="../../../assets/contacts.png" width="16" class="iconoMenu">
          <span class="full-width" *ngIf="isExpanded || isShowing">Parent Menu</span>
          <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubmenu}" *ngIf="isExpanded || isShowing">keyboard_arrow_down</mat-icon>
        </mat-list-item>
        <div class="submenu" [ngClass]="{'expanded' : showSubmenu}" *ngIf="isShowing || isExpanded">
          <a mat-list-item href="...">Submenu Item 1</a>
          <a mat-list-item href="...">Submenu Item 2</a>
          <a mat-list-item href="...">Submenu Item 3</a>				
        </div>
      </mat-nav-list>
    </mat-drawer>

	<div class="example-sidenav-content">

    <mat-toolbar color="primary" class="example-toolbar">      
      <div>
        <button mat-icon-button (click)="sidenav.toggle()" class="contentButon2"><mat-icon>menu</mat-icon></button>
        <span class="example-app-name" class="contentButon2">App Nueva</span>
      </div>
      <div>
        <mat-icon routerLink="../inicio/login" routerLinkActive="active-link">person_outline</mat-icon> &nbsp;
        <mat-icon matBadge="{{totalArticulos}}" matBadgeColor="warn" class="carrito" >shopping_cart</mat-icon>
      </div>
    </mat-toolbar>

    <!-- <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content> -->

    <section class="contentPage">
      <div class="tituloSeccion">
          Articulos destacados
          <!-- <hr style="border-top: 2px solid rgb(44, 44, 44); margin: 0; width: 15px;"> -->
        </div>
        
      
        <mat-grid-list class="listZonas" [cols]="breakpoint" rowHeight="260px" (window:resize)="onResize($event)">
          <!-- <mat-grid-tile
              *ngFor="let a of listArticulos" >
              <div (click)="insertaLinea(a)">      
                <img src="data:image/gif;base64,{{a.strImagen}}">
                <div class="total"><div class="centrado2">{{a.strNombre}}</div></div>
              </div> 
          </mat-grid-tile> -->        
      
          <mat-grid-tile>
              <ul>      
                <li><img src="https://3.bp.blogspot.com/-LeStMAAqgOc/WWw_VZrzk0I/AAAAAAAHMic/roZEamXdbaoix5-E0saO6RqycWQ6jnF1wCLcBGAs/s1600/Twitter-Download-PNG.png" height="140px"></li>
                <!-- <div class="total"><div class="centrado2">TEXTO</div></div> -->
                <li><div class="textoDescripcion">Tacens Mars Gaming MRB 2" Consola Pórtatil Retro Negra</div></li>
                <li><div class="precio">399 €</div></li>
              </ul>            
          </mat-grid-tile>
      
          <mat-grid-tile>
            <div>      
              <img src="https://img.icons8.com/material/4ac144/256/twitter.png" height="140px">
              <!-- <div class="total"><div class="centrado2">TEXTO</div></div> -->
            </div> 
          </mat-grid-tile>
      
          <mat-grid-tile>
            <div>      
              <img src="https://3.bp.blogspot.com/-LeStMAAqgOc/WWw_VZrzk0I/AAAAAAAHMic/roZEamXdbaoix5-E0saO6RqycWQ6jnF1wCLcBGAs/s1600/Twitter-Download-PNG.png" height="140px">
              <!-- <div class="total"><div class="centrado2">TEXTO</div></div> -->
            </div> 
          </mat-grid-tile>
      
          <mat-grid-tile>
            <div>      
              <img src="https://img.icons8.com/material/4ac144/256/twitter.png" height="140px">
              <!-- <div class="total"><div class="centrado2">TEXTO</div></div> -->
            </div> 
          </mat-grid-tile>
      
      
          <mat-grid-tile>
            <div>      
              <img src="https://3.bp.blogspot.com/-LeStMAAqgOc/WWw_VZrzk0I/AAAAAAAHMic/roZEamXdbaoix5-E0saO6RqycWQ6jnF1wCLcBGAs/s1600/Twitter-Download-PNG.png" height="140px">
              <!-- <div class="total"><div class="centrado2">TEXTO</div></div> -->
            </div> 
          </mat-grid-tile>
      
      
          <mat-grid-tile>
            <div>      
              <img src="https://img.icons8.com/material/4ac144/256/twitter.png" height="140px">
              <!-- <div class="total"><div class="centrado2">TEXTO</div></div> -->
            </div> 
          </mat-grid-tile>
      
      
          <mat-grid-tile>
            <div>      
              <img src="https://3.bp.blogspot.com/-LeStMAAqgOc/WWw_VZrzk0I/AAAAAAAHMic/roZEamXdbaoix5-E0saO6RqycWQ6jnF1wCLcBGAs/s1600/Twitter-Download-PNG.png" height="140px">
              <!-- <div class="total"><div class="centrado2">TEXTO</div></div> -->
            </div> 
          </mat-grid-tile>
      
      
          <mat-grid-tile>
            <div>      
              <img src="https://img.icons8.com/material/4ac144/256/twitter.png" height="140px">
              <!-- <div class="total"><div class="centrado2">TEXTO</div></div> -->
            </div> 
          </mat-grid-tile>
      
      
          <mat-grid-tile>
            <div>      
              <img src="https://3.bp.blogspot.com/-LeStMAAqgOc/WWw_VZrzk0I/AAAAAAAHMic/roZEamXdbaoix5-E0saO6RqycWQ6jnF1wCLcBGAs/s1600/Twitter-Download-PNG.png" height="140px">
              <!-- <div class="total"><div class="centrado2">TEXTO</div></div> -->
            </div> 
          </mat-grid-tile>
      
      
          <mat-grid-tile>
            <div>      
              <img src="https://img.icons8.com/material/4ac144/256/twitter.png" height="140px">
              <!-- <div class="total"><div class="centrado2">TEXTO</div></div> -->
            </div> 
          </mat-grid-tile>
      
        </mat-grid-list>
      </section>

  </div>

</mat-sidenav-container>


