<div class="misCookies" *ngIf="aceptCookies">
    <span class="close" (click)="cancelar()">&times;</span>
    <!-- <span class="close">sdjkhskljdh</span> -->
    <br>
    <div>
        Esta web inserta cookies propias para facilitar tu navegación. 
        Si continúas navegando consideramos que aceptas su uso. 
        Puedes cambiar la configuración u obtener más información en nuestra  <a (click)="cookies()">politica de cookies</a>.
    </div>

    <section>
        <button (click)="aceptaCookies()">Aceptar</button>
    </section>
</div>