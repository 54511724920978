<!--******* OCULTO LOS ELEMENTOS QUE NO SERAN VISIBLES PARA EL CATALOGO *******-->

<div class="example-container" *ngIf="showContent">
    <mat-toolbar color="primary" class="example-toolbar">
      <div class="contentMenu">
        <button mat-icon-button (click)="snav.toggle()" ><mat-icon>menu</mat-icon></button>
        <img src="data:image/gif;base64,{{imgLogo}}" height="50px" class="iconoMenu">

        <span class="example-app-name">{{nombreEst}}</span>

        
        
      </div>
      <div>
        <!-- <mat-icon *ngIf="notLogIn" (click)="logearse()">person_outline</mat-icon> &nbsp; -->
        <!-- <div *ngIf="LogIn"><span class="dot" (click)="areaCliente()">{{inicialUser}}</span></div> -->
      </div>
    </mat-toolbar>

    
  
    <mat-sidenav-container>
      
      <mat-sidenav #snav class="example-sidenav" mode="side" opened="true">

        <div class="no-v-scroll">
          <mat-nav-list>
            <mat-list-item style="height: 100%;">
              <span class="full-width miMenu" *ngIf="isExpanded || isShowing" style="font-size: 18px; max-width: 160px; font-weight: 500;">{{textoApp}}</span>
            </mat-list-item>
            <br>
            <a (click)="verDestacados()" style="padding: 8px 0; font-size: 16px; color: rgb(217, 217, 217);">
              <span style="padding-left: 16px;">Promociones</span>
            </a>
            
            <app-menu-list-item *ngFor="let item of miGupoResult.miListGrupo" [item]="item" (itemId)="parentWillTakeAction($event)"></app-menu-list-item>
            <br><br>
          </mat-nav-list>
        </div>
              
      </mat-sidenav>
  
      <mat-sidenav-content>
        <router-outlet></router-outlet>
        <br><br>
      </mat-sidenav-content>

    </mat-sidenav-container>
  </div>

  <div class="datosEstablecimiento">
    <div>
      {{miEstablecimiento.strNombre}} {{miEstablecimiento.strTelefono1}} {{miEstablecimiento.strTelefono2}}
      {{miEstablecimiento.strDireccion}} {{miEstablecimiento.strPoblacion}} {{miEstablecimiento.strCp}} {{miEstablecimiento.strProvincia}}
    </div>
  </div>

  <nav class="miSpinner" [style.display]="showSpinner ? 'block' : 'none'" >
    <mat-spinner ></mat-spinner>
  </nav>