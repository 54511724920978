<div class="miFormulario">
      <h2 class="titulo">Política de Cookies</h2>
      <br>    
      <div class="miList">    
        {{strPoliticaCookies}}
        </div>  

      <br>
    
      <div class="agregar">
        <button mat-raised-button (click)="aceptar()" class="btn-confirm">Guardar</button>
        <button mat-raised-button (click)="cancelar()" class="btn-cancel">Cancelar</button>
      </div>
    
</div>